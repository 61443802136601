import { useEffect, useState } from "react";
import styles from "./Promocode.module.scss";
import { IPromocode } from "../../types/admin";
import { fetchAddPromocode, fetchGetPromocodes } from "../../api/admin";
import { toast } from "react-toastify";
import { modal } from "../../../common/ui/Modal/ModalConnector";
import { parseISOString } from "../../utils/parseISOtoString";
import DatePicker from "react-datepicker";
import cl from "classnames";
import { EditPromocode } from "../EditPromocode/EditPromocode";

export const Promocode = () => {
	const [promocodes, setPromocodes] = useState<IPromocode[]>([]);
	const [value, setValue] = useState<string>("");
	const [reward, setReward] = useState<string>("0");
	const [active, setActive] = useState<boolean>(true);
	const [password, setPassword] = useState<string>("");
	const [start, setStart] = useState<Date | null | undefined>(new Date());
	const [end, setEnd] = useState<Date | null | undefined>(new Date());

	const getPromocodes = async (password: string) => {
		try {
			const res = await fetchGetPromocodes(password);
			if (res.data.promocodes) {
				let promocodes: IPromocode[] = [];
				res.data.tasks.map((item: IPromocode, _index: number) => {
					try {
						let promocode: IPromocode = {
							reward: item.reward,
							active: Boolean(item.active),
							start: item.start,
							end: item.end,
							id: Number(item.id),
							value: item.value,
						};
						promocodes.push(promocode);
					} catch (e) {
						console.log(e, item);
					}
					console.log("promocode", item);
				});
				console.log("promocodes", promocodes);
				setPromocodes(promocodes);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleAddPromocode = async (
		value: string,
		reward: string,
		active: boolean,
		start: string | null,
		end: string | null,
		password: string
	) => {
		try {
			const res = await fetchAddPromocode(
				value,
				reward,
				active,
				start,
				end,
				password
			);
			if (res.data) {
				toast.success("Success!");
				await getPromocodes(password);
			} else {
				toast.error("Wrong password!");
			}
		} catch (e) {
			toast.error(JSON.stringify(e));
			console.log(e);
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div className={styles.areas}>
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div>
							<div className={styles.title}>Password</div>
							<input
								value={password}
								onChange={(ev) => setPassword(ev.target.value)}
								className={styles.input}
							/>
						</div>
						<button
							onClick={() => getPromocodes(password)}
							className={cl(styles.btn, { [styles.disabled]: password === "" })}
							style={{ marginTop: "26px" }}
						>
							Get promocodes
						</button>
					</div>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<div>
							<div className={styles.title}>Value</div>
							<input
								value={value}
								onChange={(ev) => setValue(ev.target.value)}
								className={styles.input}
							/>
						</div>
						<div>
							<div className={styles.title}>Reward</div>
							<input
								value={reward}
								onChange={(ev) => setReward(ev.target.value)}
								className={styles.input}
							/>
						</div>
						<div style={{ display: "flex", gap: "14px" }}>
							<div>
								<div className={styles.title}>Active</div>
								<input
									className={styles.input}
									value={active as unknown as number}
									onChange={(e) => setActive(!active)}
									type="checkbox"
									checked={active ? true : false}
								/>
							</div>
						</div>
						<div>
							<div className={styles.title}>Start</div>
							<DatePicker
								selected={start}
								onChange={(date) => setStart(date)}
								className={styles.input}
							/>
						</div>
						<div>
							<div className={styles.title}>End</div>
							<DatePicker
								selected={end}
								onChange={(date) => setEnd(date)}
								className={styles.input}
							/>
						</div>
					</div>
				</div>
				<div className={styles.bottom}>
					<button
						onClick={() =>
							handleAddPromocode(
								value,
								reward,
								Boolean(active),
								String(start?.toISOString()),
								String(end?.toISOString()),
								password
							)
						}
						className={styles.btn}
					>
						Add promocode
					</button>
				</div>
			</div>
			{promocodes.length > 0 && (
				<table className={styles.table}>
					<thead>
						<tr>
							<td></td>
							<td>Id</td>
							<td>Value</td>
							<td>Reward</td>
							<td>Active</td>
							<td>Start</td>
							<td>End</td>
						</tr>
					</thead>
					<tbody>
						{promocodes
							.sort(function (a, b) {
								return a.id - b.id;
							})
							.map((item, index) => {
								return (
									<tr>
										<td>
											<button
												onClick={() =>
													modal.open(
														<EditPromocode
															promocode={item}
															getPromocodes={getPromocodes}
														/>
													)
												}
												className={styles.btn}
											>
												Update promocode
											</button>
										</td>
										<td>{item.id}</td>
										<td>{item.value}</td>
										<td>{item.reward}</td>
										<td>
											<input
												className={styles.input}
												style={{ height: "30px" }}
												value={item.active as unknown as number}
												type="checkbox"
												checked={item.active ? true : false}
											/>
										</td>
										<td>{String(parseISOString(item.start))}</td>
										<td>{String(parseISOString(item.end))}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			)}
		</div>
	);
};
