import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import cl from "classnames";
import styles from "./modalNew.module.scss";
import { modal } from "./ModalConnector";
import { CrossForModal } from "../CrossForModal/CrossForModal";

interface IModal {
	active: boolean;
	setActive: (active: boolean) => void;
	children: React.ReactNode;
	className?: string;
	classNameAdd?: string;
	abilityHide?: boolean;
}

export const Modal: React.FC<IModal> = ({
	active,
	setActive,
	className,
	classNameAdd,
	abilityHide,
	children,
}) => {
	const [domReady, setDomReady] = useState(false);

	useEffect(() => {
		setDomReady(true);
	}, []);

	const modalElem = document.getElementById("modal");

	return domReady && modalElem
		? ReactDOM.createPortal(
				<div
					className={cl(styles.modal, {
						[styles.active]: active,
					})}
				>
					<div
						className={cl(styles.modal__content, {
							[styles.active]: active,
						})}
						onMouseDown={(event) => event.stopPropagation()}
					>
						<div
							className={cl(
								className ? className : styles.window_default,
								classNameAdd
							)}
						>
							{children}
						</div>
						{(abilityHide === undefined || !abilityHide) && (
							<CrossForModal
								className={styles.modal__close_button}
								onClick={() => modal.close()}
								type="button"
							/>
						)}
					</div>
				</div>,
				modalElem
		  )
		: null;
};

export default Modal;
