import { useEffect, useState } from "react";
import styles from "./CreateVote.module.scss";
import {
	fetchAddVoting,
	fetchGetVoting,
	fetchUpdateVoting,
} from "../../api/admin";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { parseISOString } from "../../utils/parseISOtoString";

export const CreateVote = () => {
	const [password, setPassword] = useState<string>("");
	const [descriptionFirst, setDescriptionFirst] = useState<string>("");
	const [imageFirst, setImageFirst] = useState<string>("");
	const [descriptionSecond, setDescriptionSecond] = useState<string>("");
	const [imageSecond, setImageSecond] = useState<string>("");
	const [start, setStart] = useState<Date | null | undefined>(new Date());
	const [end, setEnd] = useState<Date | null | undefined>(new Date());
	const [voting, setVoting] = useState<{
		id: number;
		end: string;
		start: string;
		first_option: { description: string; image: string };
		second_option: { description: string; image: string };
	}>({
		id: 0,
		end: "",
		start: "",
		first_option: { description: "", image: "" },
		second_option: { description: "", image: "" },
	});

	const _handleImageChangeFirst = async (e: any) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files[0]) {
			readImageFirst(files[0]);
			console.log(files[0]);
		}
	};

	function readImageFirst(file: File) {
		readFileDataAsBase64(file).then((currentFile) => {
			console.log("file", currentFile);
			console.log(String(currentFile));
			setImageFirst(String(currentFile));
		});
	}

	const _handleImageChangeSecond = async (e: any) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files[0]) {
			readImageSecond(files[0]);
			console.log(files[0]);
		}
	};

	function readImageSecond(file: File) {
		readFileDataAsBase64(file).then((currentFile) => {
			console.log("file", currentFile);
			console.log(String(currentFile));
			setImageSecond(String(currentFile));
		});
	}

	const readFileDataAsBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (event) => {
				resolve(event.target?.result);
			};

			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(file);
		});
	};

	const getVoting = async () => {
		try {
			const res = await fetchGetVoting();
			console.log("voting", res.data);
			if (res.data.voting) {
				let votingRes: {
					id: number;
					end: string;
					start: string;
					first_option: { description: string; image: string };
					second_option: { description: string; image: string };
				} = {
					id: 0,
					end: "",
					start: "",
					first_option: { description: "", image: "" },
					second_option: { description: "", image: "" },
				};
				let first_option = JSON.parse(String(res.data.voting.first_option));
				votingRes.first_option.description = first_option.description;
				votingRes.first_option.image = first_option.image;
				votingRes.second_option = JSON.parse(
					String(res.data.voting.second_option)
				);
				votingRes.end = res.data.voting.end;
				votingRes.start = res.data.voting.start;
				votingRes.id = res.data.voting.id;
				setVoting(votingRes);
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getVoting();
	}, []);

	const addVoting = async (
		password: string,
		first_option: {
			description: string;
			image: string;
		},
		second_option: {
			description: string;
			image: string;
		},
		start: string,
		end: string
	) => {
		try {
			const res = await fetchAddVoting(
				password,
				first_option,
				second_option,
				start,
				end
			);
			if (res.data) {
				toast.success("Ok!");
				getVoting();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const updateVoting = async (
		password: string,
		first_option: {
			description: string;
			image: string;
		},
		second_option: {
			description: string;
			image: string;
		},
		start: string,
		end: string
	) => {
		try {
			const res = await fetchUpdateVoting(
				password,
				first_option,
				second_option,
				start,
				end
			);
			if (res.data) {
				toast.success("Ok!");
				getVoting();
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={styles.content}>
			{voting.id !== 0 && (
				<div className={styles.voting}>
					<div>
						<div>Description #1</div>
						<div>{voting.first_option.description}</div>
					</div>
					<div>
						<div>Image #1</div>
						<img
							src={voting.first_option.image}
							alt=""
							style={{ maxWidth: "200px" }}
						/>
					</div>
					<div>
						<div>Description #2</div>
						<div>{voting.second_option.description}</div>
					</div>
					<div>
						<div>Image #2</div>
						<img
							src={voting.second_option.image}
							alt=""
							style={{ maxWidth: "200px" }}
						/>
					</div>
					<div>
						<div>Start</div>
						<div>{String(parseISOString(voting.start))}</div>
					</div>
					<div>
						<div>End</div>
						<div>{String(parseISOString(voting.end))}</div>
					</div>
				</div>
			)}
			<div>
				<div>
					<div className={styles.title}>Password</div>
					<input
						value={password}
						onChange={(ev) => setPassword(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>Start</div>
					<DatePicker
						selected={start}
						onChange={(date) => {
							console.log(date);
							setStart(date);
						}}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>End</div>
					<DatePicker
						selected={end}
						onChange={(date) => setEnd(date)}
						className={styles.input}
					/>
				</div>
			</div>
			<div>
				<div>
					<div className={styles.title}>Description #1</div>
					<input
						value={descriptionFirst}
						onChange={(ev) => setDescriptionFirst(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>Description #2</div>
					<input
						value={descriptionSecond}
						onChange={(ev) => setDescriptionSecond(ev.target.value)}
						className={styles.input}
					/>
				</div>
			</div>
			<div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
				<label htmlFor="upload-photo" className={styles.btn}>
					Choose Image #1
				</label>
				<input
					type="file"
					id="upload-photo"
					onChange={(e) => _handleImageChangeFirst(e)}
					style={{ display: "none" }}
				/>
				<img src={imageFirst} alt="" style={{ maxWidth: "200px" }} />
				<label htmlFor="upload-photo1" className={styles.btn}>
					Choose Image #2
				</label>
				<input
					type="file"
					id="upload-photo1"
					onChange={(e) => _handleImageChangeSecond(e)}
					style={{ display: "none" }}
				/>
				<img src={imageSecond} alt="" style={{ maxWidth: "200px" }} />
			</div>
			<div style={{ display: "flex", gap: "14px" }}>
				<div
					className={styles.btn}
					onClick={() => {
						if (
							imageFirst !== "" &&
							imageSecond !== "" &&
							descriptionFirst !== "" &&
							descriptionSecond !== "" &&
							password !== ""
						) {
							addVoting(
								password,
								{
									description: descriptionFirst,
									image: imageFirst,
								},
								{
									description: descriptionSecond,
									image: imageSecond,
								},
								String(start?.toISOString()),
								String(end?.toISOString())
							);
						}
					}}
					style={{ width: "277px" }}
				>
					Add
				</div>
				<div
					className={styles.btn}
					onClick={() => {
						if (
							imageFirst !== "" &&
							imageSecond !== "" &&
							descriptionFirst !== "" &&
							descriptionSecond !== "" &&
							password !== ""
						) {
							updateVoting(
								password,
								{
									description: descriptionFirst,
									image: imageFirst,
								},
								{
									description: descriptionSecond,
									image: imageSecond,
								},
								String(start?.toISOString()),
								String(end?.toISOString())
							);
						}
					}}
					style={{ width: "277px" }}
				>
					Update
				</div>
			</div>
		</div>
	);
};
