const SERVER_DOMAIN_PROD = "https://api.tsar.company/";
const SERVER_DOMAIN_DEV = "https://proxyb.coreguild.fun/";

const SERVER_DOMAIN_PROD_NEOBOX = "https://apigame.neobox.world";
const SERVER_DOMAIN_DEV_NEOBOX = "http://80.85.140.82:4003";

const SERVER_DOMAIN_PROD_CORE = "https://api.webdrasill.com";
const SERVER_DOMAIN_DEV_CORE = "https://api.webdrasill.com";

let CONTAINER_SERVER_DOMAIN = ""
if (process.env.REACT_APP_MODE === "dev") {
    CONTAINER_SERVER_DOMAIN = SERVER_DOMAIN_DEV
}
if (process.env.REACT_APP_MODE === "prod") {
    CONTAINER_SERVER_DOMAIN = SERVER_DOMAIN_PROD
}

export const SERVER_DOMAIN = CONTAINER_SERVER_DOMAIN;
export const STATE_GUEST_PASSWORD: boolean = true;
